import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const ContentHeader = styled.section`
  .bread-crumb {
    span,
    a {
      color: ${grayscale[500]} !important;
    }
  }

  @media ${device.tablet} {
    align-items: center;
  }

  @media ${device.desktopLG} {
    min-height: calc(100vh - 73px);
  }

  button,
  a {
    @media ${device.desktopLG} {
      max-width: 376px;
    }
    @media ${device.desktopXL} {
      max-width: 456px;
    }
  }
`
