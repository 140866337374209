import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

// Components
import BreadCrumb from 'src/components/Breadcrumb'
import OpenModal from 'src/components/OpenModal'

// Styles
import { ContentHeader } from './style'

// Imges
import usePageQuery from '../../../pageQuery'

type HeroProps = {
  setIsModalOpenPj: Function;
}

const Hero = ({ setIsModalOpenPj }: HeroProps) => {
  const data = usePageQuery()

  return (
    <ContentHeader id='cartao-pj' className='bg-white pt-4 pb-5 pt-lg-0 d-flex position-relative justify-content-center justify-content-md-between'>
      <div className='container pb-lg-5'>
        <div className='row align-items-center'>
          <div className='d-none d-lg-block col-12 col-md-6 col-xl-5 offset-xl-1 pl-lg-0 order-md-last mb-2 mb-md-0 text-right '>
            <Img fluid={data.heroTesteAB.fluid} loading='eager' alt='Cartão Inter' className='float-lg-right' />
          </div>
          <div className='col-12 col-md-12 col-lg-6 pr-lg-0'>
            <div className='d-none d-md-block'>
              <BreadCrumb
                sectionName='Cartão de crédito empresarial Inter sem anuidade'
                type='Empresas'
                text='Cartão Empresarial'
                link='/empresas/cartao-empresarial/'
              />
            </div>
            <h1 className='fs-24 fs-md-32 fs-xl-42 lh-30 lh-md-37 lh-xl-47 fw-600 text-grayscale--500 mt-sm-3 mt-lg-4'>
              Cartão de crédito empresarial Inter sem anuidade
            </h1>
            <p className='fs-14 fs-md-18 lh-17 lh-md-22 text-grayscale--500 mt-4'>
              <span className='d-lg-block'>Conheça as vantagens de ter um cartão Business no Inter e garanta o melhor para sua empresa. Com o <strong>Inter Mastercard Microbusiness,</strong> você tem saques ilimitados e sem pagar nenhuma taxa.</span>
            </p>
            <div className='w-100 d-none d-lg-flex flex-column flex-md-row flex-lg-column f-lg-column align-items-center justify-content-start justify-content-md-between justify-content-lg-start mt-5 mt-sm-0 mt-md-5'>
              <div className='w-100 w-md-90'>
                <button data-home-pj='pj' onClick={() => setIsModalOpenPj(true)} className='fs-14 btn text-white btn--lg btn-orange--extra text-none rounded-3 fw-700'>
                  Abrir conta PJ gratuita
                </button>
              </div>
              <div className='w-100 w-md-90'>
                <OpenModal to='open-your-account-form'>
                  <button data-home-mei='hero' className='btn btn--outline btn--lg btn--orange rounded-2 fs-14 fw-700 text-none mt-4 mt-md-0 mt-lg-3'>
                    Abrir conta MEI gratuita
                  </button>
                </OpenModal>
              </div>
            </div>
          </div>
        </div>
        <div className='row d-lg-none mt-sm-4'>
          <div className='col-12 col-md-6'>
            <div className='w-100'>
              <Link to='/empresas/conta-digital/pessoa-juridica/' data-home-pj='pj' className='fs-14 btn text-white btn--lg btn-orange--extra text-none rounded-3 fw-700'>
                Abrir conta PJ gratuita
              </Link>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='w-100'>
              <Link
                to='/empresas/conta-digital/mei/' data-home-pj='mei'
                className='btn btn--outline btn--lg btn--orange rounded-2 fs-14 fw-700 text-none mt-3 mt-md-0 mt-lg-3'
              >
                Abrir conta MEI gratuita
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default Hero
