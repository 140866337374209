import React, { useState } from 'react'

// Components
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import OpenAccountPJForm from 'src/components/UI/Forms/OpenAccountPJForm'

// hooks
import useDomReady from 'src/hooks/window/useDomReady'

// Style
import { Wrapper } from './../style'

import pageContext from './pageContext.json'

import Hero from './sections/hero/_index'
import CashBack from './../sections/cashback/_index'
import MEICreditCard from './../sections/meiCreditCard/_index'
import DebitAndCreditFunctionSection from './../sections/debitCreditFunction/_index'
import CreditOnTime from './../sections/creditOnTime/_index'
import PracticalitySection from './../sections/practicality/_intex'
import DigitalPayment from './../sections/digitalPayment/_index'
import DigitalWallet from './../sections/digitalWallet/_index'
import BenefitsSection from './../sections/benefits/_index'
import FaqSection from './../sections/faq/_index'

const ContaDigitalPJ = () => {
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const domReady = useDomReady()

  const openAccountPJFormModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountPJForm closeModal={() => setIsModalOpen(false)} />
    </Modal>
  )
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openAccountPJFormModal}
        <Hero setIsModalOpenPj={setIsModalOpen} />
        <CashBack />
        <DebitAndCreditFunctionSection />
        <MEICreditCard setIsModalOpenPj={setIsModalOpen} />
        <CreditOnTime setIsModalOpenPj={setIsModalOpen} />
        <PracticalitySection />
        <DigitalPayment />
        <DigitalWallet />
        <BenefitsSection />
        <FaqSection />
      </Layout>
    </Wrapper>
  )
}

export default ContaDigitalPJ
